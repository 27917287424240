import * as React from 'react';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { datadogRum } from '@datadog/browser-rum-slim';
import type { EmotionCache } from '@emotion/react';
import { CacheProvider } from '@emotion/react';
import { ApiSwrProvider } from '@seccl/api-swr';
import { SecclProvider } from '@seccl/ui/components/provider';
import { createRootCSSVariables } from '@seccl/ui/utils/color';
import type { AppProps } from 'next/app';
import Head from 'next/head';

import ErrorBoundary from '../components/errors/errorBoundary/errorBoundary';
import { FirmContext } from '../context/firm.context';
import { useNProgress } from '../hooks/useNProgress';
import { useSessionTimer } from '../hooks/useSessionTimer';
import { createEmotionCache } from '../utils/emotion';
import { injectDatadogRum } from '../utils/rum';
import { isServer } from '../utils/window';

import '../styles/global.scss';
import '@seccl/fonts/assets/brown.css';
import '@seccl/forms/forms.css';
import '@seccl/ui/ui.css';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const isDatadogRumCandidate = (host?: string) => {
  if (!host) {
    return false;
  }

  const exclusions = ['local.seccl.tech', 'localhost'];
  const result = !exclusions.some(item => host.includes(item));
  return result;
};

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

/**
 * Custom Next.js App component
 * This component is used to initialize pages with a persistent layout or shared styles.
 * @param {AppProps} props - Props for the custom Next.js App component
 * @returns {React.ReactElement} JSX element for the custom App component
 */
export default function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps: { session, ...pageProps },
}: MyAppProps) {
  const initRef = React.useRef(false);
  const isLoggedIn = !!session?.userId;
  const isPlaywright = process.env.NEXT_PUBLIC_IS_PLAYWRIGHT === 'true';

  useNProgress();
  const ddRumInitRef = React.useRef(false);
  useSessionTimer(initRef, isLoggedIn, session?.firmId, session?.nodeId, !!isPlaywright);
  if (isServer && process.env.NODE_ENV === 'development' && process.env.MOCKING_ENABLED === 'true') {
    import('../utils/mocks');
  }

  React.useEffect(() => {
    if (isDatadogRumCandidate(window?.location?.host) && !ddRumInitRef.current) {
      ddRumInitRef.current = true;
      injectDatadogRum();
    }

    if (ddRumInitRef && session?.userId && !datadogRum.getGlobalContext()?.userId) {
      datadogRum.setGlobalContext({ userId: session.userId });
    }
  }, [session]);

  const branding = {
    'brand-primary': pageProps?.branding?.backgroundColor,
    'brand-secondary': pageProps?.branding?.brandColor,
    accent: pageProps?.branding?.ctaColor,
    'brand-primary-contrast': pageProps?.branding?.backgroundColorContrast,
    'brand-secondary-contrast': pageProps?.branding?.brandColorContrast,
    'accent-contrast': pageProps?.branding?.ctaColorContrast,
  };

  return (
    <UserProvider user={session?.user}>
      <Head>
        <style>{createRootCSSVariables(branding)}</style>
        {pageProps?.branding?.favicon && <link rel="shortcut icon" href={pageProps.branding.favicon} />}
      </Head>

      <FirmContext.Provider
        value={{
          firmId: session?.firmId,
          nodeId: session?.nodeId,
          scopes: session?.scopes,
          footerCopyright: pageProps?.branding?.footerCopyright,
          orderExecutionPolicyUrl: pageProps?.branding?.orderExecutionPolicyUrl,
          authenticated: !!session,
          windowTitle: pageProps?.branding?.windowTitle ?? 'SECCL',
        }}
      >
        <ApiSwrProvider value={{ revalidateOnMount: false, revalidateOnFocus: false }}>
          <CacheProvider value={emotionCache}>
            <SecclProvider
              config={{
                logoPath: pageProps?.branding?.headerLogoUrl,
              }}
            >
              <ErrorBoundary>
                <Component {...pageProps} />
              </ErrorBoundary>
            </SecclProvider>
          </CacheProvider>
        </ApiSwrProvider>
      </FirmContext.Provider>
    </UserProvider>
  );
}
